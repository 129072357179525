<script>
    import notif from '$lib/stores/notif';
    import { Item, ItemSet } from '$lib/classes/item';
</script>

<section class='maincontent'>
    {#each Item.items as [type, item] (type)}
        <span>{JSON.stringify(item)}</span>
    {/each}
</section>

<style>
    section {
        z-index: 2;
        padding-top: 0;
        background-color: var(--bg-color-base-dark);
        border-width: var(--border-width);
        border-color: var(--bg-color-base);
        max-width: 768px;
    }
</style>